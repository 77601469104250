interface Tents {
  east: HTMLElement
  south: HTMLElement
  west: HTMLElement
  north: HTMLElement
  center: HTMLElement | null
}

interface Wheel {
  container: HTMLElement
  wheel: HTMLElement
  east: HTMLElement
  south: HTMLElement
  west: HTMLElement
  north: HTMLElement
  center: HTMLElement
}

interface WheelOnClickOptions {
  toggle?: boolean
  pushHistory?: boolean
}

interface ManagedHistoryPage {
  url: string
  state?: unknown
  title?: string
}

interface ManagedHistoryOptions {
  state?: unknown
  title?: string
}

type FormatTitle = (title: string) => string

class ManagedHistory {
  pages: ManagedHistoryPage[] = []
  formatTitle: FormatTitle

  constructor(formatTitle: FormatTitle = (title) => title) {
    this.formatTitle = formatTitle

    window.addEventListener('popstate', (event) => {
      console.log('popstate managed')
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      while (this.pages.length > 0 && event.state?.url !== this.current.url) {
        this.quietlyPopState()
      }
    })
  }

  get current() {
    return this.pages[this.pages.length - 1]
  }

  get lastIndex() {
    return this.pages.length === 0 ? 0 : this.pages.length - 1
  }

  pushState(url: string, options: ManagedHistoryOptions = {}) {
    this.pages.push({
      url,
      ...options,
    })

    history.pushState({ managed: true, url }, '', url)
    document.title = this.formatTitle(options.title ?? '')
    console.log('[ManagedHistory] pushState, url:', url, ', pages:', this.pages)
  }

  replaceState(url: string, options: ManagedHistoryOptions = {}) {
    this.pages[this.lastIndex] = {
      url,
      ...options,
    }

    history.replaceState({ managed: true, url }, '', url)
    document.title = this.formatTitle(options.title ?? '')
    console.log(
      '[ManagedHistory] replaceState, url:',
      url,
      ', pages:',
      this.pages,
    )
  }

  back() {
    const oldUrl = document.location.href

    history.back()

    // For some reason, `history.back()` likes to not work
    // The first back call seems to always work in chrome, but further calls then just fail and do nothing
    // Waiting 100ms and then calling it *again* works for some ungodly reason
    // There is no reason why these calls should fail, why???
    setTimeout(() => {
      if (document.location.href === oldUrl) {
        history.back()
      }
    }, 100)

    console.log('[ManagedHistory] back, pages:', this.pages)
  }

  /**
   * Either go back (if there is available history), or replace state with what's "expected"
   *
   * For example, if the user visits an embed, they usually go `/` -> `/west` -> `/west/video/...` and dismissing the modal should put them back at `/west` by going back
   *
   * If the user visits `/west/video/...` directly, dismissing the modal should send them to `/west`, which can't be done via back
   * @param url The url to replace with
   * @param state The state to add, if any
   */
  backOrReplace(url: string, options: ManagedHistoryOptions = {}) {
    console.log(
      '[ManagedHistory] backOrReplace, url:',
      url,
      ', pages:',
      this.pages,
    )
    if (this.pages.length > 0) {
      this.back()
    } else {
      this.replaceState(url, options)
    }
  }

  /**
   * Pop state (removing a page from navigation history) while not touching history itself
   *
   * Used when history is popped by the user (back button) and has some common functionality used for programmatic navigation
   */
  quietlyPopState() {
    const old = this.pages.pop()
    document.title = this.formatTitle(old?.title ?? '')
    console.log('[ManagedHistory] quietlyPopState, pages:', this.pages)
  }
}

function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const managedHistory = new ManagedHistory(
  (t) => `${t ? capitalize(t) : 'Medicine Wheel'} - Sacred Lodges`,
)

const directions = [
  'east',
  'south',
  'west',
  'north',
  'center',
] as const satisfies (keyof Wheel)[]

window.addEventListener('load', () => {
  history.replaceState(null, '', document.URL)
})

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', () => void main())
} else {
  main()
}

function main() {
  const wheel = getWheel()
  const tents = getTents()

  // #region EventHandlers

  function createWheelOnClick(
    toClass: string,
    { toggle = true, pushHistory = true }: WheelOnClickOptions = {},
  ) {
    return () => {
      if (spinAbortController) {
        spinAbortController.abort()
        spinAbortController = null
      }

      const wheelPart = wheel[toClass as keyof Wheel]
      const path = window.location.pathname.slice(1)

      if (wheel.container.classList.contains(toClass) && toggle) {
        wheel.container.classList.remove(toClass)
        wheelPart?.classList.remove('hover')
        if (pushHistory) {
          managedHistory.backOrReplace('/')
        }
      } else {
        wheel.container.classList.remove(...directions)

        for (const key in wheel) {
          wheel[key as keyof Wheel].classList.remove('hover')
        }

        if (toClass) {
          wheel.container.classList.add(toClass)
          wheelPart?.classList.add('hover')
        }

        if (pushHistory) {
          if (path === '') {
            managedHistory.pushState(`/${toClass}`, { title: toClass })
          } else {
            managedHistory.replaceState(`/${toClass}`, { title: toClass })
          }
        }
      }

      siteNav.classList.remove('open')
      if (navInput) navInput.checked = false
      wheelOnMouseLeave(true)

      for (const link of navLIArray) {
        const href = link.getAttribute('href')
        if (href === `/${toClass}`) {
          link.classList.add('active')
          const attr = document.createAttribute('aria-current')
          attr.value = 'page'
          link.attributes.setNamedItem(attr)
        } else {
          link.classList.remove('active')
          if (link.hasAttribute('aria-current')) {
            link.attributes.removeNamedItem('aria-current')
          }
        }
      }
    }
  }

  function createWheelOnMouseEnter(toClass: string) {
    return () => {
      if (spinAbortController) {
        spinAbortController.abort()
        spinAbortController = null
      }

      if (
        (toClass === 'north-hover' || toClass === 'south-hover') &&
        wheel.container.classList.contains('center-hover')
      ) {
        return
      }

      if (directions.some((dir) => wheel.container.classList.contains(dir))) {
        return
      }

      wheel.container.classList.remove(
        'east-hover',
        'south-hover',
        'west-hover',
        'north-hover',
        'center-hover',
      )

      if (toClass) {
        wheel.container.classList.add(toClass)
      }
    }
  }

  function wheelOnMouseLeave(removeAll = false) {
    wheel.container.classList.remove(
      'east-hover',
      'south-hover',
      'west-hover',
      'north-hover',
    )

    if (removeAll) {
      wheel.container.classList.remove('center-hover')
    }
  }

  // #endregion EventHandlers

  // #region EventListeners

  for (const direction of directions) {
    wheel[direction].addEventListener('click', createWheelOnClick(direction))
    wheel[direction].addEventListener(
      'mouseenter',
      createWheelOnMouseEnter(`${direction}-hover`),
    )
    wheel[direction].addEventListener('mouseleave', () => wheelOnMouseLeave())

    const titleSections =
      direction === 'center'
        ? [
            document.getElementById('center-section-top'),
            document.getElementById('center-section-bottom'),
          ]
        : [document.getElementById(`${direction}-section`)]

    for (const titleSection of titleSections) {
      if (titleSection === null) continue

      titleSection.addEventListener('click', createWheelOnClick(direction))
      const onMouseEnter = createWheelOnMouseEnter(`${direction}-hover`)
      titleSection.addEventListener('mouseenter', () => {
        onMouseEnter()
        wheel[direction].classList.add('hover')
      })
      titleSection.addEventListener('mouseleave', () => {
        wheelOnMouseLeave()
        if (!wheel.container.classList.contains(direction)) {
          wheel[direction].classList.remove('hover')
        }
      })
    }

    wheel.wheel.addEventListener('mouseleave', () => {
      wheel.container.classList.remove(
        'east-hover',
        'south-hover',
        'west-hover',
        'north-hover',
        'center-hover',
      )
    })
  }

  // #endregion EventListeners

  // #region Nav

  const siteNav = document.getElementsByClassName('main-nav')[0]
  const siteNavList = document.getElementById('site-nav')

  if (!siteNav || !siteNavList) {
    throw new Error('Failed to find site nav, did the page fail to load?')
  }

  const navInput = document.getElementById('nav-toggle') as HTMLInputElement

  if (!navInput) {
    throw new Error('Failed to find nav input, did the page fail to load?')
  }

  const updateNavOpenState = () => {
    if (navInput.checked) {
      siteNav.classList.add('open')
    } else {
      siteNav.classList.remove('open')
    }
  }

  navInput.addEventListener('change', updateNavOpenState)

  const navListItem = siteNavList.getElementsByTagName('li')
  const navLIArray = Array.from(navListItem)

  const onLinkFocusIn = () => {
    navInput.checked = true
    updateNavOpenState()
  }

  const onLinkFocusOut = () => {
    // Focus out should check the new active element to see if it's in the nav
    // If it is, don't close the nav
    const focusedElement = document.querySelector(':focus')
    if (
      focusedElement &&
      navLIArray.some((li) => li.contains(focusedElement))
    ) {
      return
    }

    navInput.checked = false
    updateNavOpenState()
  }

  for (const listItem of navLIArray) {
    const link = listItem.getElementsByTagName('a')[0]
    if (!link) continue

    const section = new URL(link.href).pathname.slice(1) as keyof Wheel
    const wheelOnSectionMouseEnter = createWheelOnMouseEnter(`${section}-hover`)

    listItem.addEventListener('mouseenter', () => {
      if (!(section in wheel)) {
        return
      }

      wheelOnSectionMouseEnter()
      wheel[section].classList.add('hover-nav')
    })

    listItem.addEventListener('mouseleave', () => {
      if (!(section in wheel)) {
        return
      }

      wheelOnMouseLeave(true)
      wheel[section].classList.remove('hover-nav')
    })

    listItem.addEventListener('click', (e) => {
      if (!(section in wheel)) {
        return
      }

      e.preventDefault()
      createWheelOnClick(section, { toggle: false })()
    })

    listItem.addEventListener('focusin', onLinkFocusIn)
    listItem.addEventListener('focusout', onLinkFocusOut)
  }

  const navHeader = document.getElementById('nav-header')

  if (!navHeader) {
    throw new Error('Failed to find nav header, did the page fail to load?')
  }

  navHeader.addEventListener('click', () => {
    siteNav.classList.remove('open')
    managedHistory.backOrReplace('/')
    resetWheel(wheel)
  })

  // #endregion Nav

  registerPosters()
  registerBackButtons()

  let spinAbortController: AbortController | null = new AbortController()
  const iframe = document.getElementById(
    'embed-modal-iframe',
  ) as HTMLIFrameElement

  function handleNewPath(pathParts: string[]) {
    if (pathParts[0] in wheel || pathParts[0] === '') {
      createWheelOnClick(pathParts[0], {
        toggle: false,
        pushHistory: false,
      })()
    }

    if (pathParts[1] === 'video') {
      const [videoId, hashParam] = pathParts[2].split('-')
      const src = vimeoEmbedLink(videoId, hashParam)
      iframe.src = src

      document.getElementById('embed-modal')?.classList.add('show')
    }
  }

  const pathParts = window.location.pathname.slice(1).split('/')

  window.addEventListener('popstate', (event) => {
    setTimeout(() => {
      console.log(
        'popstate',
        document.location.href,
        JSON.stringify(event.state),
      )
      if (document.getElementById('embed-modal')?.classList.contains('show')) {
        closeModal()
      }

      const pathParts = window.location.pathname.slice(1).split('/')
      handleNewPath(pathParts)
    }, 0)
  })

  if (pathParts[0] === '') {
    void spinWheel(wheel, tents, spinAbortController.signal).catch(() => {})
  } else {
    handleNewPath(pathParts)
  }
}

function sleep(time: number) {
  return new Promise((resolve) => setTimeout(resolve, time))
}

function getWheel(): Wheel {
  const container = document.getElementById('wheel-container')
  const wheel = document.getElementById('wheel')
  const east = document.getElementById('wheel-east')
  const south = document.getElementById('wheel-south')
  const west = document.getElementById('wheel-west')
  const north = document.getElementById('wheel-north')
  const center = document.getElementById('wheel-center-outer')

  if (!container || !wheel || !east || !south || !west || !north || !center) {
    throw new Error('Failed to find wheel, did the page fail to load?')
  }

  return {
    container,
    wheel,
    east,
    south,
    west,
    north,
    center,
  }
}

function getTents(): Tents {
  const east = document.getElementById('east-tent')
  const south = document.getElementById('south-tent')
  const west = document.getElementById('west-tent')
  const north = document.getElementById('north-tent')

  if (!east || !south || !west || !north) {
    throw new Error('Failed to find tents, did the page fail to load?')
  }

  return {
    east,
    south,
    west,
    north,
    center: null,
  }
}

const animTime = 2000
async function spinWheel(wheel: Wheel, tents: Tents, signal: AbortSignal) {
  const selectADirection = document.getElementById('select-a-direction')
  if (!selectADirection) {
    throw new Error(
      'Failed to find select a direction, did the page fail to load?',
    )
  }

  selectADirection.style.opacity = '0'

  signal.addEventListener('abort', () => {
    selectADirection.style.removeProperty('opacity')
    for (const dir of directions) {
      wheel[dir].classList.remove('hover')
      tents[dir]?.classList.remove('hover')
    }
    wheel.container.classList.remove('center-start-anim')
    wheel.container.classList.remove('center-image-anim')
  })

  const sleepOrAbort = async (time: number) => {
    await sleep(time)
    if (signal.aborted) {
      await sleep(time)
      throw new Error('Aborted')
    }
  }

  let prev: string | null = null

  for (const dir of directions) {
    if (prev) {
      wheel[prev as keyof Wheel].classList.remove('hover')
      tents[prev as keyof Tents]?.classList.remove('hover')
    }

    wheel[dir].classList.add('hover')
    tents[dir]?.classList.add('hover')
    await sleepOrAbort(animTime)
    prev = dir
  }

  if (prev) {
    wheel[prev as keyof Wheel].classList.remove('hover')
    tents[prev as keyof Tents]?.classList.remove('hover')
  }

  wheel.container.classList.add('center-start-anim')
  wheel.container.classList.add('center-image-anim')
  await sleepOrAbort(animTime)
  wheel.container.classList.remove('center-start-anim')
  wheel.container.classList.remove('center-image-anim')

  selectADirection.style.removeProperty('opacity')
}

function resetWheel(wheel: Wheel) {
  wheel.container.classList.remove('east', 'south', 'west', 'north', 'center')
  wheel.east.classList.remove('hover')
  wheel.south.classList.remove('hover')
  wheel.west.classList.remove('hover')
  wheel.north.classList.remove('hover')
  wheel.center.classList.remove('hover')
}

function closeModal() {
  const embedModal = document.getElementById('embed-modal')
  const iframe = document.getElementById(
    'embed-modal-iframe',
  ) as HTMLIFrameElement

  iframe.contentWindow?.postMessage({ method: 'pause' }, '*')
  embedModal?.classList.remove('show')
}

function closeEmbedAndChangeHistory() {
  closeModal()
  const direction = window.location.pathname.slice(1).split('/')[0]
  managedHistory.backOrReplace(`/${direction}`)
}

function registerPosters() {
  const embedModal = document.getElementById('embed-modal')
  const embedModalContent = document.getElementById('embed-modal-content')
  const iframe = document.getElementById(
    'embed-modal-iframe',
  ) as HTMLIFrameElement

  if (!embedModal || !embedModalContent) {
    throw new Error('Failed to find embed modal, did the page fail to load?')
  }

  const posters = document.getElementsByClassName(
    'vimeo-embed-button',
  ) as HTMLCollectionOf<HTMLElement>

  for (const poster of Array.from(posters)) {
    const videoId = poster.dataset['vimeoId']
    const hashParam = poster.dataset['vimeoHash']

    if (!videoId || !hashParam) return

    const src = vimeoEmbedLink(videoId, hashParam)

    poster.addEventListener('click', (e) => {
      e.preventDefault()

      const direction = window.location.pathname.slice(1).split('/')[0]
      managedHistory.pushState(`/${direction}/video/${videoId}-${hashParam}`, {
        title: direction,
      })

      iframe.src = src
      embedModal.classList.add('show')
    })
  }

  const closeEmbedButton = document.getElementById('close-embed-modal')

  if (!closeEmbedButton) {
    throw new Error('Failed to find close button, did the page fail to load?')
  }

  closeEmbedButton.addEventListener('click', closeEmbedAndChangeHistory)

  embedModal.addEventListener('click', (e) => {
    if (e.target === embedModal) {
      closeEmbedAndChangeHistory()
    }
  })
}

function registerBackButtons() {
  const backButtons = document.getElementsByClassName('go-back-button')

  for (const backButton of Array.from(backButtons)) {
    backButton.addEventListener(
      'click',
      managedHistory.back.bind(managedHistory),
    )
  }
}

function vimeoEmbedLink(videoId: string, hashParam: string): string {
  return `https://player.vimeo.com/video/${videoId}?h=${hashParam}&dnt=1&transparent=false`
}
